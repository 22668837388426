import React from 'react';

const TechnologyPartners = () => {
  return (
    <div>
      TechnologyPartners
    </div>
  );
}

export default TechnologyPartners;
