import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

import Cloath01 from "../assets/voice/cloth.jpg";
import Floer01 from "../assets/voice/floar.png";

function handleClick(event) {
  event.preventDefault();
}

const OurClients = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Voice Of Clients - Pinke Capital";


    const metaDescription = document.createElement("meta");
    metaDescription.name = "description";
    metaDescription.content =
      "Listen to the voices of our clients as they share their experiences with Pinke Capital. Hear their testimonials and stories of empowerment.";
    document.head.appendChild(metaDescription);

    return () => {
      document.head.removeChild(metaDescription);
    };
  }, []);

  const handleMouseEnter = () => {
    setIsVideoPlaying(true);
  };

  const handleMouseLeave = () => {
    setIsVideoPlaying(false);
  };

  return (
    <div>
      <div className="com-img">
        <div className="common-head board">
          <h2>Voice Of Our Clients</h2>
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to="/">
                Home
              </Link>
              <Link underline="none" color="inherit">
                Our Impact
              </Link>
              <Typography color="text.primary">Voice Of Our Clients</Typography>
            </Breadcrumbs>
          </div>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-5">
            <h3 className="">Gayathiri Devi</h3>
            <h5 className="mb-3">Coimbatore</h5>
            <iframe
              width="100%"
              height="250"
              src="https://www.youtube.com/embed/sy_b_X3a_ZQ?si=eqZ36dEEz3dVrAsA&start=164"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              autoPlay={isVideoPlaying ? 1 : 0}
              muted
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-5">
            <h3 className="">Kalamani</h3>
            <h5 className="mb-3">Coimbatore</h5>
            <iframe
              width="100%"
              height="250"
              src="https://www.youtube.com/embed/sy_b_X3a_ZQ?si=JqYTtXx3fw2mceom&start=210"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              autoPlay={isVideoPlaying ? 1 : 0}
              muted
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurClients;
