import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../styles/slider.css";

import Hero01 from "../assets/hero/1.gif";
import Hero02 from "../assets/hero/2.gif";
import Hero03 from "../assets/hero/3.gif";
import Hero04 from "../assets/hero/alix.png";
import Hero05 from "../assets/hero/neha.png";

export default class HeroSwiper extends React.Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false,
    };
    return (
      <div className=" hero-container">
        <Slider {...settings}>
          <div class>
            <div className="heroimg">
              <img src={Hero01} alt="" />
            </div>
          </div>
          <div>
            <div className="heroimg">
              <img src={Hero02} alt="" />
            </div>
          </div>
          <div>
            <div className="heroimg">
              <img src={Hero03} alt="" />
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}
