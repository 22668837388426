// partners.js
import BcPar01 from "../assets/partners/crif.png";
import BcPar02 from "../assets/partners/1630503440969.jfif";
import BcPar03 from "../assets/partners/credit transunion.svg";
import BcPar04 from "../assets/partners/equ.png";

import landPar01 from "../assets/partners/monexo.png";
import landPar02 from"../assets/partners/1630503440969.jfif";
import landPar03 from"../assets/partners/rarfincare.svg";
import landPar04 from"../assets/partners/sf.webp";
import landPar05 from"../assets/partners/aphelion.png";
import landPar06 from"../assets/partners/bwd.jpg";

import fund01 from"../assets/partners/rarfincare.svg";
import fund02 from"../assets/partners/rarfincare.svg";
import fund03 from"../assets/partners/rarfincare.svg";

import techPar01 from "../assets/partners/xtra.png";
import techPar02 from "../assets/partners/image.png";


import RatPar02 from "../assets/partners/infomerics.png";

import CriPar01 from "../assets/partners/equ.png";
import CriPar02 from "../assets/partners/crif.png";
import CriPar03 from "../assets/partners/cibil.png";
import CriPar04 from "../assets/partners/experianindia_logo.jfif";



const partners = [
  {
    part_name: "Funding Partners",
    part_id: "landing-partners",
    partners: [
      { part_img: landPar01 },
      { part_img: landPar02 },
      { part_img: landPar03 },
      { part_img: landPar04 },
      { part_img: landPar05 },
      { part_img: landPar06 },
    ],
  },
  // {
  //   part_name: "Funding Partners",
  //   part_id: "Funding-partners",
  //   partners: [
  //     { part_img: fund01 },
  //     { part_img: fund02 },
  //     { part_img: fund03 },
  //   ],
  // },
  {
    part_name: "Technical Partners",
    part_id: "tech-partners",
    partners: [
      { part_img: techPar01 },
      { part_img: techPar02 },
    ],
  },
  // {
  //   part_name: "strategic Partners",
  //   part_id: "strategic-partners",
  //   partners: [
  //     { part_img: BcPar01 },
  //     { part_img: BcPar02 },
  //     { part_img: BcPar03 },
  //     { part_img: BcPar04 },

  //   ],
  // },
  {
    part_name: "Rating agencies",
    part_id: "rating-agencies",
    partners: [
      { part_img: RatPar02 },
      { part_img: RatPar02 },
     
    ],
  },

  {
    part_name: "Credit Bureau",
    part_id: "credit-partners",
    partners: [
      { part_img: CriPar01 },
      { part_img: CriPar02 },
      { part_img: CriPar03 },
      { part_img: CriPar04 },
     
    ],
  },
];


export default partners;

