import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Pro01 from "../assets/loan/pinke capital-39.jpg";
import Pro02 from "../assets/loan/qwer.png";
import "../styles/finance.css";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const Financial = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Financial Solutions - Pinke Capital";

    const metaDescription = document.createElement("meta");
    metaDescription.name = "description";
    metaDescription.content = "Explore Pinke Capital's financial solutions, including working capital loans and business expansion loans.";
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement("meta");
    metaKeywords.name = "keywords";
    metaKeywords.content = "financial solutions, working capital loans, business expansion loans, Pinke Capital";
    document.head.appendChild(metaKeywords);

    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);

  return (
    <div>
    <div className="com-img">
      <div className="common-head  board">
        <h2>Financial Solutions</h2>
        <div role="presentation" onClick={handleClick}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" to="/">
              Home
            </Link>
    
            <Typography color="text.primary">Financial Solutions</Typography>
          </Breadcrumbs>
        </div>
      </div>
    </div>
    <div className="fin-container container">
      <h2 className="text-center mt-5">Our Products</h2>
      <div className="fin-para text-center mt-5 mb-5">
        Pinke-Capital offers adequate loan products for variety of purposes
        which includes working capital needs, agri inputs, agri value-chain,
        dairy farming , poultry keeping , stock purchase, equipment / machine
        purchase/hire, digitalization of business and business premises
        improvement/lease
      </div>
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 product-col">
          <Card>
            <CardMedia
              sx={{ height: 240 }}
              image={Pro02}
              title=" Working Capital Loan"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Working Capital Loan
              </Typography>
    
              <div className="pro-boxes">
                <div className="pro-box">
                  <div className="pro-head">Loan Amount</div>
                  <div className="pro-con">Rs. 50,000- Rs. 3,00,000</div>
                </div>
                <div className="pro-box">
                  <div className="pro-head">Loan Term</div>
                  <div className="pro-con">24-36 months</div>
                </div>
                <div className="pro-box">
                  <div className="pro-head">ROI</div>
                  <div className="pro-con">24%-29%</div>
                </div>
                <div className="pro-box">
                  <div className="pro-head">Processing Fee</div>
                  <div className="pro-con">2%</div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 product-col">
          <Card>
            <CardMedia
              sx={{ height: 240 }}
              image={Pro01}
              title=" Business Expansion and Improvement Loan"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Business Expansion and Improvement Loans
              </Typography>
              <div className="pro-boxes">
                <div className="pro-box">
                  <div className="pro-head">Loan Amount</div>
                  <div className="pro-con">Rs. 1,00,000 to Rs. 5,00,000</div>
                </div>
                <div className="pro-box">
                  <div className="pro-head">Loan Term</div>
                  <div className="pro-con">36 Months</div>
                </div>
                <div className="pro-box">
                  <div className="pro-head">ROI</div>
                  <div className="pro-con">26%-29%</div>
                </div>
                <div className="pro-box">
                  <div className="pro-head">Processing Fee</div>
                  <div className="pro-con">2%</div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Financial;




