import React from 'react';

const Faq = () => {
  return (
    <div>
     <div className="commen-h">
        <div className="tit">FAQ</div>
      </div>
    </div>
  );
}

export default Faq;
