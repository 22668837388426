import React from "react";

const Career = () => {
  return <div>
    <div className="commen-h">
        <div className="tit">Career</div>
      </div>
  </div>;
};

export default Career;
