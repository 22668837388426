import React from "react";
import {
  MDBCarousel,
  MDBCarouselItem,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";

import Test01 from "../assets/test/Alix Lebec Bio Photo.JPG";
import Test02 from "../assets/test/Neha Juneja 2021.PNG";
import "../styles/test.css";
import { Helmet } from "react-helmet";

export default function Testimonial() {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Read inspiring testimonials from successful entrepreneurs and business leaders."
        />
        <meta
          name="keywords"
          content="testimonial, success stories, entrepreneurs, business leaders, inspiration"
        />
      </Helmet>
     <MDBContainer className="my-2">
      <MDBCarousel showControls dark>
        <MDBCarouselItem className="active car-item  text-center">
          <div className="card-test">
            <div className="card-left">
              <img
                src={Test01}
                alt="avatar"
                className="rounded-circle shadow-1-strong mb-4"
                style={{ width: "150px" }}
              />
               <div className="test-text-bo">
                
              <h5 className="mb-1 test-h">Alix Lebec</h5>
              <p className="text-p">Founder, MD & CEO , Lebec Consulting </p>
                </div>
            </div>
            <div className="card-right">
              <MDBRow className="d-flex justify-content-center">
                <MDBCol>
                  <p className="text-muted">
                    <MDBIcon fas icon="quote-left" className="pe-2" />
                    As long as we continue to invest with outdated frameworks
                    and a lack of diverse leaders and investors at the
                    decision-making table, we will fail to create space for the
                    future. We need a paradigm shift in mainstream finance that
                    recognizes the urgency of prioritizing future risk such as
                    climate risk and the unique value women investors and
                    women-led businesses in emerging markets bring to our global
                    economy.
                    <MDBIcon fas icon="quote-right" className="pe-2" />
                  </p>
                </MDBCol>
              </MDBRow>
              
            </div>
          </div>
        </MDBCarouselItem>
        <MDBCarouselItem className=" car-item text-center">
          <div className="card-test">
            <div className="card-right">
              <MDBRow className="d-flex justify-content-center">
                <MDBCol>
                  <p className="text-muted">
                    <MDBIcon fas icon="quote-left" className="pe-2" />
                    Leveling the playing field for women is the most effective
                    means of meeting our economic and social progress goals as a
                    country.
                    <MDBIcon fas icon="quote-right" className="pe-2" />
                  </p>
                </MDBCol>
              </MDBRow>
              
            </div>
            <div className="card-left">
              <img
                src={Test02}
                alt="avatar"
                className="rounded-circle shadow-1-strong mb-4"
                style={{ width: "150px" }}
              />
             <div className="test-text-bo">
             <h5 className="mb-1 test-h">Neha Juneja</h5>
              <p className="text-p">Co-Founder & CEO, IndiaP2P</p>
             </div>
            </div>
          </div>
        </MDBCarouselItem>
      
      </MDBCarousel>
    </MDBContainer>
    </>
   
  );
}
