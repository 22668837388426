import React, { useEffect } from 'react';
import team from "../deta/team";
import { Helmet } from 'react-helmet';

import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const StrategicPartners = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [open, setOpen] = React.useState(false);
  const [selectedMember, setSelectedMember] = React.useState(null);

  const handleOpen = (member) => {
    setSelectedMember(member);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleClick = (event) => {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  };

  return (
    <div>
      <Helmet>
        <title>Our Team - Pinke Capital</title>
        <meta
          name="description"
          content="Meet the talented individuals behind Pinke Capital's success. Learn about our team members, their roles, and contributions."
        />
         <meta
          name="keywords"
          content="partners, collaborations, alliances, teamwork, joint ventures"
        />
      </Helmet>
      <div className="com-img">
        <div className="common-head  board">
          <h2>Our Team</h2>
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to="/">
                Home
              </Link>
              <Link underline="none" color="inherit">
                About
              </Link>
              <Typography color="text.primary">Our Team</Typography>
            </Breadcrumbs>
          </div>
        </div>
      </div>
      <section id="team" className="pb-5">
        <div className="container">
          <div className="row">
            {team.map((member) => (
              <div className="col-lg-3 col-md-4 coll-sm-6 col-xs-6 col-6" key={member.id}>
                <div className="cards" onClick={() => handleOpen(member)}>
                  <img
                    src={member.board_img}
                    className="card-img-top img"
                    alt={member.board_name}
                  />
                  <div className="card-body member-body">
                    <h6 className="card-title">{member.board_name}</h6>
                    <p className="card-text">{member.board_role}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {selectedMember && (
            <>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {selectedMember.board_name}
              </Typography>
              <img src={selectedMember.board_img} alt={selectedMember.board_name} />
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {selectedMember.board_role}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {selectedMember.board_text}
              </Typography>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default StrategicPartners;
