import React from 'react';

const Terms = () => {
  return (
    <div>
       <div className="commen-h">
        <div className="tit">Terms and Conditions</div>
      </div>
    </div>
  );
}

export default Terms;
