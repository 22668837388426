import React from "react";

const Privacy = () => {
  return <div>
     <div className="commen-h">
        <div className="tit">Privacy and Policy </div>
      </div>
  </div>;
};

export default Privacy;
