import React from 'react';

const Error = () => {
  return (
    <div>
      error page
    </div>
  );
}

export default Error;
