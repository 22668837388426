import React, { useState, useEffect } from "react";
import "../styles/contact.css";
import CallIcon from '@mui/icons-material/Call';
import { toast } from "react-toastify";

const ContactUs = () => {
  const [name, serName] = useState();

  const showToastMessage = (e) => {
    e.preventDefault();
    toast.success("Thanks! We Will Call You", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "ContactUs - Pinke Capital";

    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'Contact Pinke Capital for inquiries and support. Fill out the form and we will get back to you within 24 hours.';
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement('meta');
    metaKeywords.name = 'keywords';
    metaKeywords.content = 'contact, Pinke Capital, support, inquiries';
    document.head.appendChild(metaKeywords);

    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);

  return (
    <div className=" contact-body">
<div class="container contact-box">
  <div class="bg-light">
    <di v class="row">
      <div class="col-lg-8 col-md-12 p-5 bg-white rounded-3">
        <div class="d-flex mb-3 flex-column">
          <h1 class="h5 text-capitalize my-4"> Get in Touch </h1>
        </div> <div class="mb-5">
          <h1 class="h3">Contact Information</h1>
          <p class="opacity-50">
            <small>
              Fill out the from and we will get back to you whitin 24
              hours
            </small>
          </p>
        </div>
        <form class="row mb-3">
          <div class="col-md-6 p-3">
            <input
             
              placeholder="first name"
              type="text"
              name=""
              id=""
            />
          </div>
          <div class="col-md-6 p-3">
            <input
             
              placeholder="last name"
              type="text"
              name=""
              id=""
            />
          </div>
          <div class="col-md-6 p-3">
            <input
             
              placeholder="E-mail"
              type="text"
              name=""
              id=""
            />
          </div>
          <div class="col-md-6 p-3">
            <input
             
              placeholder="phone"
              type="tel"
              name=""
              id=""
            />
          </div>
          <div class="col-md">
            <textarea
             
              name=""
              placeholder="write your message"
              id=""
              cols="30"
              rows="1"
            ></textarea>
          </div>
          <div class="text-end mt-4">
            <input
             onClick={showToastMessage}
              class="btn px-4 py-3 btn-outline-dark"
              type="submit"
              value="Send Message"
            />

            
          </div>
        </form>
      </div>
      <div class="col-lg-4 col-md-12 text-white aside px-4 py-5">
       
        <div class="d-flex flex-column px-0">
          <ul class="m-0 p-0">
            <li class="d-flex justify-content-start align-items-center mb-4">
              <span class="opacity-50 d-flex align-items-center me-3 fs-2">
              </span>
              
              <span><h2>Phone</h2><br/>0422-3516124</span>
            </li>
            <li class="d-flex align-items-center r mb-4">
              <span class="opacity-50 d-flex align-items-center me-3 fs-2">
              </span>
              
              <span><h2>email</h2><br/>ponaananth@pinke.in</span>
            </li>
            <li class="d-flex justify-content-start align-items-center mb-4">
              <span class="opacity-50 d-flex align-items-center me-3 fs-2">
              </span>
              
              <span><h2>Address</h2><br/>
              9/2,Elane Enclave,Third Floor, Sathy Road, Ramakrishnapuram, Coimbatore, TamilNadu.<br/>
              Pincode: 641006
              </span>
            </li>
          </ul>
        </div>
      </div>
    </di>
  </div>
</div>
</div>
  );
};

export default ContactUs;




