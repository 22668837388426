import React, { useEffect } from "react";
import Our01 from "../assets/our/our story.jpg";
import Our02 from "../assets/png/analysis.png";
import Our03 from "../assets/png/diagram.png";
import "../styles/our.css";

function OurCompany() {
  useEffect(() => {
    window.scrollTo(0, 0);

    const metaDescription = document.createElement("meta");
    metaDescription.name = "description";
    metaDescription.content =
      "Learn about our company's story, vision, and mission. Discover how we strive to make a difference through our values and goals.";
    document.head.appendChild(metaDescription);

    return () => {
      document.head.removeChild(metaDescription);
    };
  }, []);

  return (
    <div>
      <div className="commen-h">
        <div className="tit">Our Company</div>
      </div>
      <div className="our-company-container">
        <div className="our--box">
          <div className="our-left">
            <img src={Our01} alt="" />
          </div>
          <div className="our-right">
            <div className="our-h">Our Story</div>
            <div className="our-text">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Consequuntur ratione mollitia ipsum veniam, pariatur rerum
              accusamus aliquid illum necessitatibus, placeat suscipit quos,
              deserunt earum molestias nobis modi excepturi eius eaque. Magnam
              ipsa quos explicabo qui, sapiente unde aspernatur nulla, quam
              exercitationem illum tenetur nam maiores perferendis laborum culpa
              delectus itaque eaque, dolores blanditiis optio in. Praesentium
              recusandae, cupiditate deleniti at, maxime nesciunt sapiente ipsa
              molestiae accusantium quae quaerat soluta, pariatur nisi natus ex
              voluptatum. Odit nemo asperiores officia autem iure, magni nisi!
              Eum molestias officiis id incidunt facilis non tenetur, pariatur
              cupiditate quod temporibus cum delectus at, consequatur quos
              ducimus.
            </div>
          </div>
        </div>

        <div className="our--box">
          <div className="our-left our-mission">
            <div className="our-h">Our Vision</div>
            <div className="our-inside">
              <div className="our-text">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore
                sapiente non accusantium soluta, molestias doloremque maxime
                corporis laborum repellat molestiae ipsam animi repellendus
                beatae, praesentium dolores sint quidem illum optio.
              </div>
              <img src={Our02} alt="" className="our-img" />
            </div>
          </div>

          <div className="our-right our-vision">
            <div className="our-h">Our Mission</div>
            <div className="our-inside">
              <div className="our-text">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore
                sapiente non accusantium soluta, molestias doloremque maxime
                corporis laborum repellat molestiae ipsam animi repellendus
                beatae, praesentium dolores sint quidem illum optio.
              </div>
              <img src={Our03} alt="" className="our-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurCompany;
