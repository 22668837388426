import React, { useEffect } from "react";
import "../styles/about.css";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import "../styles/about.css";
import Com01 from "../assets/compeny/office.png";

import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const Who = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Who We Are - Pinke Capital</title>
        <meta
          name="description"
          content="Learn about who we are and our mission to empower women entrepreneurs through access to finance and business support services."
        />
        <meta
          name="keywords"
          content="who we are, mission, empower, women entrepreneurs, access to finance, business support services"
        />
      </Helmet>
      <div className="com-img">
        <div className="common-head  board">
          <h2>Who We Are</h2>
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to="/">
                Home
              </Link>
              <Link underline="none" color="inherit">
                About
              </Link>
              <Typography color="text.primary">Who We Are</Typography>
            </Breadcrumbs>
          </div>
        </div>
      </div>
      <section className="about-section">
        <div className="container">
          <div className="row">
            <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
              <div className="inner-column">
                <div className="sec-title">
                  {/* <span className="title">About Pinke-Capital</span> */}
                  <h2>About Pinke-Capital</h2>
                </div>
                <div className="text">
                  India is a country of opportunities, yet women’s participation
                  in economic decision making is dismal. Financial inclusion is
                  the key to break the chains of poverty stemming from unequal
                  divisions of labour and a lack of control over economic
                  resources. Pinke Capital, the brand name of Arul financiers
                  Private Limited is a NBFC licensed by the Reserve Bank of
                  India.
                </div>
                {/* <div className="text">
          We at Pinke Capital have realised that access to finance is necessary for women entrepreneurs but not a sufficient condition to scale and thrive their business.  In most cases women entrepreneurs are forced to seek credit from informal sources when the credit supply is inadequate from formal sources. The major barriers in accessing credit are 1. Gender bias 2. Social Conditioning 3. Absence of gender sensitivity 4. Absence or inadequate business support service 5. Incongruous Credit Products. Among others, Inadequate credit supply directly forces women entrepreneurs to seek high-cost capital from money lenders and cause significant income leakages.  
          </div>
          <div className="text">
          Supplying adequate credit is the key lever to help women led businesses to scale and thrive. We at Pinke Capital exclusively focus women led business to have access to adequate working capital to expand the business along with other business support services. 
          </div> */}
                <div className="btn-box"></div>
              </div>
            </div>

            <div className="image-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column wow fadeInLeft">
                <figure className="image-1">
                  <div className="lightbox-image">
                    <img title="Pinke Capital" src={Com01} alt="no-img" />
                  </div>
                </figure>
              </div>
            </div>
          </div>
          {/* <div className="sec-title">
      <span className="title">Our Future Goal</span>
      <h2>We want to lead in innovation & Technology</h2>
    </div> */}
          <hr />
          <div className="text">
            Pinke Capital has realised that access to finance is
            necessary for women entrepreneurs but not a sufficient condition to
            scale and thrive the business. In most cases women entrepreneurs
            are forced to seek credit from informal sources when the credit
            supply is inadequate from formal sources. The major barriers in
            accessing credit are
            <ul>
              <li> 1. Gender bias</li>
              <li> 2. Social Conditioning</li>
              <li> 3.Absence of gender sensitivity</li>
              <li> 4. Absence or inadequate business support service</li>
              <li> 5. Incongruous Credit Products.</li>
            </ul>
            Among others,
            Inadequate credit supply directly forces women entrepreneurs to seek
            high-cost capital from the money lenders and cause significant income
            leakages.
          </div>
          <div className="text">
            Supplying <b>adequate and timely credit</b> is the key lever to help women led
            businesses to scale and thrive. We at Pinke Capital trying to bridge the credit gap by supplying adequate and timely business loans to women entrepreneurs.

          </div>
          <div className="text">
            Pinke Capital also realizes that women entrepreneurs operating informal economy need appropriate support services like financial literacy, digital financing, business communication and market linkages.
            Our <b>Pinke finance and leadership academy</b> impart the above much needed training to women entrepreneurs.
          </div>
          {/* <div className="text">
      Here we are trying to give you all kinds of technical content,
      whether it is related to designing or functionality. We are creating
      content on a lot of languages and will continue to make it free of
      cost even if you use it without any problem. Which is a very
      important thing.
    </div>
    <div className="text">
      Here you can also share the content you create, if our technical
      team likes it, then we will also share it on our blog.
    </div> */}
          {/* <div className="text">
      In the end, I would say keep visiting our website and enjoy the
      quality content.
    </div> */}
        </div>
      </section>
    </div>
  );
};

export default Who;



