import React from "react";
import "../styles/load.css"

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="three-body">
        <div className="three-body__dot" />
        <div className="three-body__dot" />
        <div className="three-body__dot" />
      </div>
    </div>
  );
};

export default Loader;
