import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const Access = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'Learn about access to adequate credit at Pinke Capital.';
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement('meta');
    metaKeywords.name = 'keywords';
    metaKeywords.content = 'access to credit, Pinke Capital, finance';
    document.head.appendChild(metaKeywords);

    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);

  return (
    <div>
      <div className="com-img">
        <div className="common-head  board">
          <h2>Access to adequate credit</h2>
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to="/">
                Home
              </Link>
              <Link underline="none" color="inherit">
                What We Do
              </Link>
              <Typography color="text.primary">Access to adequate credit</Typography>
            </Breadcrumbs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Access;
