import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "../styles/partner.css";

import PartOne from "../components/PartOne";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const Partners = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Partners - Pinke Capital</title>
        <meta
          name="description"
          content="Explore our valuable partnerships and collaborations. Learn how we work together to achieve common goals."
        />
        <meta
          name="keywords"
          content="partners, collaborations, alliances, teamwork, joint ventures"
        />
      </Helmet>
      <div className="com-img">
        <div className="common-head  board">
          <h2>Partners</h2>
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to="/">
                Home
              </Link>
              <Typography color="text.primary">Partners</Typography>
            </Breadcrumbs>
          </div>
        </div>
      </div>
      <div className="part-box">
        <PartOne />
      </div>
    </div>
  );
};

export default Partners;
