import React from "react";

const Customer = () => {
  return (
    <div>
      <div className="commen-h">
        <div className="tit">Customer Grievances</div>
      </div>
    </div>
  );
};

export default Customer;
