import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Map01 from "../assets/out reac/Kerala_and_Tamil_Nadu.png";
import '../styles/core.css'
function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const OutReach = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "OutReach - Pinke Capital";

        const metaDescription = document.createElement("meta");
    metaDescription.name = "description";
    metaDescription.content ="Explore Pinke Capital's outreach efforts, including disbursement statistics, states covered, branches, and support provided to women entrepreneurs. View our branch locations on the map.";
    document.head.appendChild(metaDescription);
    return () => {
      document.head.removeChild(metaDescription);
    };
  }, []);

  return (
    <div className="container">
      <div className="com-img">
        <div className="common-head  board">
          <h2>Our Outreach</h2>
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to="/">
                Home
              </Link>
              <Link underline="none" color="inherit">
                Our Impact
              </Link>
              <Typography color="text.primary">Our OutReach</Typography>
            </Breadcrumbs>
          </div>
        </div>
      </div>
      <div className="section pre-container">
        <h5 className="section-title h1 text-center ">Our Outreach</h5>
        <div className="row ">
          <div className="col-12  pre-right mb-5">
            <div className="row pre-right-row">
              <div className="col-3">
                <div className="pre-card">
                  <div className="pre-h">35 Cr</div>
                  <div className="pre-text">Disbursement </div>
                </div>
              </div>
              <div className="col-3">
                <div className="pre-card">
                  <div className="pre-h">2</div>
                  <div className="pre-text">States</div>
                </div>
              </div>
              <div className="col-3">
                <div className="pre-card">
                  <div className="pre-h">13</div>
                  <div className="pre-text">Branches</div>
                </div>
              </div>

              <div className="col-3">
                <div className="pre-card">
                  <div className="pre-h">6,750 </div>
                  <div className="pre-text">Women entrepreneurs </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-5  pre-left">
            <img src={Map01} className="map" alt="" />
            <div className="branch-name-box">
              <h3> Our Branches</h3>
              <ul className="branch-list">
                <li className="branch-name">Ganapathi, Coimbatore</li>
                <li className="branch-name">Alandurai, Coimbatore</li>
                <li className="branch-name">Kariyampalayam, Coimbatore</li>

                <li className="branch-name">Thamaraipakkam, Chennai</li>
                <li className="branch-name">Sriperumbudur, Chennai</li>
                <li className="branch-name">Ponneri, Chennai</li>
                <li className="branch-name">Madhanur, Chennai</li>
                <li className="branch-name">Walajabad, Chennai</li>

                <li className="branch-name">Koduvayur, Kerala</li>
                <li className="branch-name">Palakkad, Kerala</li>
                <li className="branch-name">Kongad, Kerala</li>
                <li className="branch-name">Ottapalam, Kerala</li>

                <li className="branch-name">Srirangam, Trichy</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="pre-row"></div>
      </div>
    </div>
  );
};

export default OutReach;
