import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import partnersData from "../deta/partners";

import "../styles/partner slider.css";

export default class PartOne extends React.Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: true,
    };


    return (
      <div className="section partner-container">
        {partnersData &&
          partnersData.map((partnerGroup) => (
            <div className="slider-partner" id={partnerGroup.part_id}  key={partnerGroup.part_name}>
              <h1 className="h3 mt-5 mb-5 partner-heading">
                {partnerGroup.part_name}
              </h1>
              <Slider {...settings}>
                {partnerGroup.partners &&
                  partnerGroup.partners.map((partner, index) => (
                    <div key={index}>
                      <div className="part-img-box">
                        <img src={partner.part_img} alt="" />
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          ))}
      </div>
    );
  }
}
