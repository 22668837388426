import React, { useEffect } from "react";
import "../styles/core.css";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const Core = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    const metaDescription = document.createElement("meta");
    metaDescription.name = "description";
    metaDescription.content ="Learn about the core values of Pinke Capital.";
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement("meta");
    metaKeywords.name = "keywords";
    metaKeywords.content = "core values, Pinke Capital, about";
    document.head.appendChild(metaKeywords);

    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);

  return (
    <div>
      <div className="com-img">
        <div className="common-head  board">
          <h2>Core Value</h2>
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to="/">
                Home
              </Link>
              <Link underline="none" color="inherit">
                About
              </Link>
              <Typography color="text.primary">Core Value</Typography>
            </Breadcrumbs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Core;
