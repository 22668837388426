import React, { useEffect } from "react";
import Img01 from "../assets/resource/image (1).JPG";
import Img02 from "../assets/resource/IMG-20240213-WA0007.jpg";
import "../setupTests/commen.css";

const Resources = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Resources - Pinke Capital";

    const metaDescription = document.createElement("meta");
    metaDescription.name = "Woman Entrepreneurs ";
    metaDescription.content =
      "Check out our resources page for Women entrepreneurs .";
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement("meta");
    metaKeywords.name = "keywords";
    metaKeywords.content = "resources, Pinke Capital, empowerment, finance";
    document.head.appendChild(metaKeywords);

    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);

  return (
    <>
      <div className="container mt-5 mb-5 text-center">
        <h2 className="my-3">What We've Been</h2>
        <div className="row mt-2">
          <div className="col-xl-4 col-lg-4 col-md-3 col-sm-6 col-xs-6">
            <iframe
              width="100%"
              height="255"
              src="https://www.youtube.com/embed/sy_b_X3a_ZQ?si=qUYgDG1qDtaukzBY&start=232"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              muted
            />
            <p>
              Pinke Capital - Way of Empowering Women Entrepreneurs - Access to
              Adequate Capital
            </p>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-3 col-sm-6 col-xs-6">
            <img src={Img01} alt="" className="re-img" />
            <p>
              Pinke Capital New Branch Opened at Kariyampalayam, Coimbatore.
            </p>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-3 col-sm-6 col-xs-6">
            <iframe
              width="100%"
              height="255"
              src="https://www.youtube.com/embed/BePxPS_OqY8?si=4dJHGnfCSIENhDd4"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
            <p>Our MD's interview with YouTube Influencer London Tamizhan </p>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-3 col-sm-6 col-xs-6">
            <img src={Img02} alt="" className="re-img" />
            <p>
              Pinke Capital New Branch Opened at <br /> Sri Rangam, Trichy.{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Resources;
