import React from 'react';

const WomenTraining = () => {
  return (
    <div>
      
      <div className="commen-h">
        <div className="tit">Pinke-Capital Women Entrepreneurship Training</div>
      </div>
    </div>
  );
}

export default WomenTraining;
