import React, { useState } from 'react';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function MobileNav() {
  const [state, setState] = useState({
    top: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const list = (anchor) => (
    <Box
      className="draw-list"
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <Link to="/">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>
        </Link>
  
        <Divider />
        <ListItem disablePadding>
        <Accordion className="w-100">
          <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={stopPropagation}>
            <ListItemText primary="About Us" />
          </AccordionSummary>
          <AccordionDetails>
           
              <Link to="/who-we-are">
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemText primary="Who We Are" />
                  </ListItemButton>
                </ListItem>
              </Link>
                      <Divider />

              <Link to="/board">
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemText primary="Board" />
                  </ListItemButton>
                </ListItem>
              </Link>
              <Link to="/strategic-advisors">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary=" Advisors" />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/our-team">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary=" Team" />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/our-vision-mission">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary=" Our Vision, Mission & Values" />
            </ListItemButton>
          </ListItem>
        </Link>
           
          </AccordionDetails>
        </Accordion>
        </ListItem>
     
    
  
        {/* <Divider />
  
        <Link to="/our-core-value">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary=" Core Values" />
            </ListItemButton>
          </ListItem>
        </Link> */}
  
        <Divider />
  
        <Link to="/What-we-do">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="What We Do" />
            </ListItemButton>
          </ListItem>
        </Link>
  
        <Divider />
  
        <Divider />
        <Link to="/financial-solutions">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="   Financial Solutions" />
            </ListItemButton>
          </ListItem>
        </Link>
  
        <Divider />
  
        <ListItem disablePadding>
        <Accordion className="w-100">
          <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={stopPropagation}>
            <ListItemText primary="Our Impact" />
          </AccordionSummary>
          <AccordionDetails>
            <div >
            <Link to="/voice-of-our-clients">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary=" Voice Of Our Clients" />
            </ListItemButton>
          </ListItem>
        </Link>
        <Divider />
        <Link to="/out-outreach">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary=" Our Outreach" />
            </ListItemButton>
          </ListItem>
        </Link>
            </div >
          </AccordionDetails>
        </Accordion>
        </ListItem>
        <Divider />
        <Link to="/partners">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Partners" />
            </ListItemButton>
          </ListItem>
        </Link>
        <Divider />
        <Link to="/pinke-academy">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Pinke Academy" />
            </ListItemButton>
          </ListItem>
        </Link>
        <Divider />
        <Link to="/resources">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Resources " />
            </ListItemButton>
          </ListItem>
        </Link>
        <Divider />
        <Link to="/contact-us">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Contact us " />
            </ListItemButton>
          </ListItem>
        </Link>
  
        <Divider />
      </List>
    </Box>
  );

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Mobile navigation menu for navigating through different sections of the website."
        />
        <meta
          name="keywords"
          content="mobile nav, navigation, menu, links, react, swipeable drawer"
        />
      </Helmet>
      <Button className="menu-icon" onClick={toggleDrawer('top', true)} style={{ display: state.top ? 'none' : 'block' }}>
        <MenuIcon />
      </Button>
      <IconButton onClick={toggleDrawer('top', false)} style={{ display: state.top ? 'block' : 'none' }}>
        <CloseIcon />
      </IconButton>
      <SwipeableDrawer
        className="draw"
        anchor="top"
        open={state.top}
        onClose={toggleDrawer('top', false)}
        onOpen={toggleDrawer('top', true)}
      >
        {list('top')}
      </SwipeableDrawer>
    </div>
  );
}
