import React, { useEffect } from "react";
import "../styles/ourteam.css";
import team from "../deta/team";

import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

// function handleClick(event) {
//   event.preventDefault();
//   console.info("You clicked a breadcrumb.");
// }

const OurTeam = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Team - Pinke Capital";

    const metaDescription = document.createElement("meta");
    metaDescription.name = "description";
    metaDescription.content =
      "Meet our dedicated team members who contribute to our success and mission. Learn about their roles and expertise.";
    document.head.appendChild(metaDescription);

    return () => {
      document.head.removeChild(metaDescription);
    };
  }, []);

  // const handleOpen = (member) => {
  //   setSelectedMember(member);
  //   setOpen(true);
  // };

  // const handleClose = () => setOpen(false);

  const handleClick = (event) => {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  };

  return (
    <div>
      <div className="com-img">
        <div className="common-head  board">
          <h2>Our Team</h2>
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to="/">
                Home
              </Link>
              <Link underline="none" color="inherit">
                About
              </Link>
              <Typography color="text.primary">Our Team</Typography>
            </Breadcrumbs>
          </div>
        </div>
      </div>
      <section id="team" className="pb-5">
        <div className="container">
          <div className="row">
            {team.map((member) => (
              <div
              className="col-lg-3 col-md-4 coll-sm-6 col-xs-12 col-12"
                key={member.id}
              >
                {/* <div className="cards" onClick={() => handleOpen(member)}> */}
                <div className="cards" >
                  <img
                    src={member.board_img}
                    className="card-img-top img"
                    alt={member.board_name}
                  />
                  <div className="card-body member-body">
                 <div>
                 <h6 className="card-title">{member.board_name}</h6>
                    <p className="card-text">{member.board_role}</p>
                 </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {selectedMember && (
            <div className="model-pop">
              <div className="model-img">
                <img
                  src={selectedMember.board_img}
                  className="model-img"
                  alt={selectedMember.board_name}
                />
              </div>
              <div className="model-body">
                {" "}
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {selectedMember.board_name}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {selectedMember.board_role}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {selectedMember.board_text}
                </Typography>
              </div>
            </div>
          )}
        </Box>
      </Modal> */}
    </div>
  );
};

export default OurTeam;



