import Board01 from "../assets/team/saravanan.png";
import Board02 from "../assets/team/blessey.jpg";
import Board03 from "../assets/team/balaji.jpg";
import Board04 from "../assets/team/nagalakshmi.jpg";
import Board05 from "../assets/team/deepak.jpg";
import Board06 from "../assets/team/kish.png";
import Board07 from "../assets/team/manikandan.png";
import Board08 from "../assets/team/ram2.png";
import Board09 from "../assets/team/ajeesh.jpg";
import Board10 from "../assets/team/mohan.png";
import Board11 from "../assets/team/senthil.png";


const team = [
  {
    board_name: "Saravanan C",
    board_role: "Business Head (TN & Kerala)",
    board_img: Board01,
    board_text: "Business-Head (Pinke-Capital) ",
  },
  {
    board_name: "Dr MohanKumar M",
    board_role: "IT-Head",
    board_img: Board10,
    board_text:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quos voluptatum quisquam incidunt! Distinctio adipisci tempore exercitationem aut autem, ad provident.",
  },
  // {
  //   board_name: "Blessey Florence ",
  //   board_role: "Credit - Manager",
  //   board_img: Board02,
  //   board_text:
  //     "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quos voluptatum quisquam incidunt! Distinctio adipisci tempore exercitationem aut autem, ad provident.",
  // },
  {
    board_name: " Balaji N",
    board_role: "Auditing-Head",
    board_img: Board03,
    board_text:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quos voluptatum quisquam incidunt! Distinctio adipisci tempore exercitationem aut autem, ad provident.",
  },

  {
    board_name: "Kishore T Y",
    board_role: "Area Manager",
    board_img: Board06,
    board_text:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quos voluptatum quisquam incidunt! Distinctio adipisci tempore exercitationem aut autem, ad provident.",
  },  


  {
    board_name: "Deepak Shiva T",
    board_role: "IT-Manager",
    board_img: Board05,
    board_text:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quos voluptatum quisquam incidunt! Distinctio adipisci tempore exercitationem aut autem, ad provident.",
  },



];

export default team;
