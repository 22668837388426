import React, { useState, useEffect } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Who from "./pages/Who";
import Career from "./pages/Career";
import ContactUs from "./pages/ContactUs";
import Customer from "./pages/Customer";
import Directors from "./pages/Directors";
import Faq from "./pages/Faq";
import OurTeam from "./pages/OurTeam";
import OurPresence from "./pages/OurPresence";
import Partners from "./pages/Partners";
import Privacy from "./pages/Privacy";
import ProductServices from "./pages/productServices";
import Terms from "./pages/Terms";
import WomenTraining from "./pages/WomenTraining";
import ErrorPage from "./pages/Error";
import OurCompany from "./pages/OurCompany";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Loader from "./components/Loader";


import Strategic from "./pages/Strategic";
import OurVM from "./pages/OurVM";
import Core from "./pages/Core";
import Access from "./pages/Access";
import Business from "./pages/Business";
import Financial from "./pages/Financial";
import OutReach from "./pages/OutReach";
import OurClients from "./pages/OurClients";
import FinancialPartners from "./pages/FinancialPartners";
import TechnologyPartners from "./pages/TechnologyPartners";
import StrategicPartners from "./pages/StrategicPartners";
import Pinke from "./pages/Pinke";
import Resoures from "./pages/Resoures";
import What from "./pages/What";

import ScrollToTopButton from "./components/ScrollToTopButton ";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <div className="main">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/who-we-are" element={<Who />} />
          <Route path="/board" element={<Directors />} />
          <Route path="/strategic-advisors" element={<Strategic />} />
          <Route path="/our-team" element={<OurTeam />} />
          <Route path="/our-vision-mission" element={<OurVM/>} />
          <Route path="/our-core-value" element={<Core/>} />
          <Route path="/access-to-adequate-credit" element={<Access/>} />
          <Route path="/business-support-Services" element={<Business/>} />
          <Route path="/financial-solutions" element={<Financial/>} />
          <Route path="/voice-of-our-clients" element={<OurClients/>} />
          <Route path="/out-outreach" element={<OutReach/>} />
          <Route path="/financial-partners" element={<FinancialPartners/>} />
          <Route path="/technology-partners" element={<TechnologyPartners/>} />
          <Route path="/strategic-partners" element={<StrategicPartners/>} />
          <Route path="/pinke-academy" element={<Pinke/>} />
          <Route path="/resources" element={<Resoures/>} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/customer" element={<Customer />} />
          <Route path="/What-we-do" element={<What />} />



          <Route path="/our-company" element={<OurCompany />} />
          <Route path="/career" element={<Career />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/our-presence" element={<OurPresence />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/product-services" element={<ProductServices />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/women-training" element={<WomenTraining />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <Footer />
      <ScrollToTopButton/>
      </div>
    </>
  );
}

export default App;
