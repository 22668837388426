import React, { useEffect } from "react";
import "../styles/director.css";
import board from "../deta/board";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "../styles/model.css"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80vh",
  outline: "none",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Directors = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    const metaDescription = document.createElement("meta");
    document.title = "Board - Pinke Capital";

    metaDescription.name = "description";
    metaDescription.content =
      "Learn about the board members of Pinke Capital.";
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement("meta");
    metaKeywords.name = "keywords";
    metaKeywords.content = "board members, Pinke Capital, about";
    document.head.appendChild(metaKeywords);

    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);

  const [open, setOpen] = React.useState(false);
  const [selectedMember, setSelectedMember] = React.useState(null);

  const handleOpen = (member) => {
    setSelectedMember(member);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleClick = (event) => {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  };

  return (
    <div>
      <div className="com-img">
        <div className="common-head board">
          <h2>Board</h2>
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to="/">
                Home
              </Link>
              <Link underline="none" color="inherit">
                About
              </Link>
              <Typography color="text.primary">Board</Typography>
            </Breadcrumbs>
          </div>
        </div>
      </div>
      <section id="team" className="pb-5">
        <div className="container">
          <div className="row">
            {board.map((member) => (
              <div
                className="col-lg-3 col-md-4 coll-sm-6 col-xs-12 col-12"
                key={member.id}
              >
                <div className="cards" onClick={() => handleOpen(member)}>
                  <img
                    src={member.board_img}
                    className="card-img-top img"
                    alt={member.board_name}
                  />
                  <div className="card-body member-body">
                    <div className="member-boxes">
                      <h6 className="card-title">{member.board_name}</h6>
                      <p className="card-text">{member.board_role}</p>
                    </div>
                    <div className="view-icon">
                      <i className="fa-sharp fa-solid fa-eye" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {selectedMember && (
            <div className="model-pop">
              <div className="model-img">
                <img
                  src={selectedMember.board_img}
                  className="model-img"
                  alt={selectedMember.board_name}
                />
              </div>
              <div className="model-body">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {selectedMember.board_name} - {selectedMember.board_r}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {selectedMember.board_role}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {selectedMember.board_text}
                </Typography>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default Directors;
