import React, { useEffect } from "react";
import "../styles/pre.css";
import Map01 from "../assets/india/presence.png";

const OurPresence = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    const metaDescription = document.createElement("meta");
    metaDescription.name = "description";
    metaDescription.content =
      "Discover our presence across various states, districts, villages, and branches. Learn about our extensive network and the impact we make in local communities.";
    document.head.appendChild(metaDescription);

    return () => {
      document.head.removeChild(metaDescription);
    };
  }, []);

  return (
    <div>
      <div className="commen-h">
        <div className="tit">Our Presence</div>
      </div>
      <div className="section pre-container">
        <div className="pre-par">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis
          incidunt dolorem eaque, nobis veniam, ab soluta alias amet harum
          consequatur, labore ea. Officia delectus tenetur quidem iste explicabo
          minima sit.
        </div>
        <div className="pre-row">
          <div className="row">
            <div className="col-6 pre-left">
              <img src={Map01} className="map" alt="" />
            </div>
            <div className="col-6 pre-right">
              <div className="row pre-right-row">
                <div className="col-6">
                  <div className="pre-card">
                    <div className="pre-h">2</div>
                    <div className="pre-text">STATE</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="pre-card">
                    <div className="pre-h">50</div>
                    <div className="pre-text">BRANCHES</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="pre-card">
                    <div className="pre-h">30</div>
                    <div className="pre-text">DISTRICTS</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="pre-card">
                    <div className="pre-h">16,640</div>
                    <div className="pre-text">NUMBER OF VILLAGES</div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="pre-card">
                    <div className="pre-h">640</div>
                    <div className="pre-text">NUMBER OF LOAN OFFICERS</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurPresence;
