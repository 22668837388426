import React, { useState, useEffect } from "react";
import "../styles/home.css";
import HeroSwiper from "../components/HeroSwiper";
import Testimonial from "../components/Testimonial";
import VideoPopup from "../components/VideoPopup";
import Approach01 from "../assets/aproach/OBJECTS.svg";
import OurTimeline from "../components/OurTimeline";
import { MDBIcon } from "mdb-react-ui-kit";
import Image1 from "../assets/image1(1).png";
import Image2 from "../assets/image2.png";
import Image3 from "../assets/image3.png";
const Home = () => {
  const [isVideoPopupOpen, setIsVideoPopupOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    const metaDescription = document.createElement("meta");
    metaDescription.name = "description";
    metaDescription.content =
      "Pinke-Capital offers access to high-impact growth capital, transforming women-led businesses. Explore our impact, services, and testimonials.";
    document.head.appendChild(metaDescription);

    return () => {
      document.head.removeChild(metaDescription);
    };
  }, []);

  const openVideoPopup = () => {
    setIsVideoPopupOpen(true);
  };

  const closeVideoPopup = () => {
    setIsVideoPopupOpen(false);
  };

  return (
    <div>
      <HeroSwiper />
      <br />
      <div className="section out-impact-container">
        <h1 className="impact-head">Our Impact By the Numbers</h1>
        <div className="container card-boxed">
          <div className="row">
            <div className="    col-6 col-lg-3 col-md-6 col-sm-6 col-xs-6">
              <div className="card card-nymber card-1">
                <h1>2</h1>
                <p>States</p>
              </div>
            </div>
            <div className=" col-6 col-lg-3 col-md-6 col-sm-6 col-xs-6">
              <div className="card card-nymber card-2">
                <h1>35 Cr </h1>
                <p>Disbursement </p>
              </div>
            </div>
            <div className=" col-6 col-lg-3 col-md-6 col-sm-6 col-xs-6">
              <div className="card card-nymber card-3">
                <h1>13</h1>
                <p>Branches</p>
              </div>
            </div>
            <div className=" col-6 col-lg-3 col-md-6 col-sm-6 col-xs-6">
              <div className="card card-nymber card-2">
                <h1>50+</h1>
                <p>Staff</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container about-us-container  ">
     
          <div className="impact-head ">
            Quotes About US
            </div>            

      

        <div className="text tex msac">


          <b>Pinke Capital </b> has realised that access to finance is
          necessary for women entrepreneurs but not a sufficient condition to
          scale and thrive the business. In most cases women entrepreneurs
          are forced to seek credit from informal sources when the credit
          supply is inadequate from formal sources. The major barriers in
          accessing credit are


        </div>

      </div> */}
      <div className="section approach-container">
        <div className="approach-text-box">
          {/* <h3 className="enq-h">Quality Makes the Belief for Customers</h3> */}
          <div className="eng-sub">ABOUT PINKE-CAPITAL</div>

          <div className="approach-text">
            <b>Pinke Capital </b> has realised that access to finance is
            necessary for women entrepreneurs but not a sufficient condition to
            scale and thrive the business. In most cases women entrepreneurs are
            forced to seek credit from informal sources when the credit supply
            is inadequate from formal sources. 
          </div>

          {/* <div className="approach">
          <img src={Approach01} alt="" />
        </div> */}
        </div>
      </div>
      <div className="section enquiry-container">
        <div className="enq-box">
          <div className="eny-left"></div>
          <div className="enq-right">
            {/* <div className="eng-sub">ABOUT PINKE-CAPITAL</div> */}
            <h3 className="enq-h">
              Access to high impact making growth capital transforms women led
              businesses{" "}
            </h3>
            <div className="enq-container text-center">
              We Believe in making a better future for our women entrepreneurs
            </div>
            <a
              id="play-video"
              onClick={openVideoPopup}
              className="video-play-button"
              href="#"
            >
              <span />
            </a>
          </div>
        </div>
      </div>

      {/* <div className="time-container">
    <OurTimeline/>
    </div> */}

      <div className="container our-home-container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 our-h-box">
            <div className="title-header">
              <h3>Our Vision</h3>
            </div>
            <div className="">
              <div className=" our-h-img">
                <img src={Image1} className="" alt="No Image" />
              </div>
              <div className="">
                <div className="text-center">
                  The vision of Pinke Capital is to remain as a favoured
                  financial institution to women entrepreneurs.
                </div>
              </div>
            </div>
          </div>
          <div className=" col-lg-6 col-md-6 col-sm-12 col-xs-12 our-h-box ">
            <div className="title-header ">
              <h3>Our Mission</h3>
            </div>
            <div className="our-h-img">
              <img src={Image2} className="" alt="No Image" />
            </div>
            <div className="">
              <div className="text-center">
                PINKE Capital’s mission is to innovate and facilitate access to
                high impact making financial products and business support
                services to women led businesses.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section test-section">
        <h3 className="enq-hea text-center">
          {" "}
          What Thought Leaders And Practitioners Say !{" "}
        </h3>

        <Testimonial />
      </div>

      {isVideoPopupOpen && (
        <VideoPopup isOpen={isVideoPopupOpen} onClose={closeVideoPopup} />
      )}
    </div>
  );
};

export default Home;
