import Board01 from "../assets/board/CEO.png";
import Board02 from "../assets/board/manjunathh.png";
import Board03 from "../assets/board/shhhubrajpg-removebg.png";
import Board04 from "../assets/board/nandha.png";
import Board05 from "../assets/board/dharm.jpg";

const board = [
  {
    board_name: "Dr.L.H.Manjunath",
    board_role: "Chairman",
    board_img: Board02,
    board_text:
      " Dr L H Manjunath is the Executive Director, Shri KshethraDharmasthala Rural Development Project Dr. L H Manjunath has over three decades of experience in banking, development, enterprise creation and economic empowerment of women. He has developed SKDRDP into a unique institution for microfinance and micro insurance for the poor. Dr. Manjunath has scaled the organization from serving a single taluk to reaching over 3.6 million families across Karnataka. He is the author of 3 books and more than 40 articles, and serves on five advisory committees. He was also awarded the highest civilian award by government of Karnataka for his contribution in rural development.x",
  },
  {
    board_name: "Dr Dharmanand Mortha",
    board_role: "Director",
    board_img: Board05,
    board_text:
      "Dr Mortha with an astounding entrepreneurial drive and a global perspective of business operations stands as a highly successful C-Level business leader and a visionary with a portfolio of consistent success in maximizing corporate performance by accelerating growth, revenue, market share and profit, and enhancing value in domestic and international markets. Over the years, Dr. Mortha has worked in diverse industries such as agro-chemicals, banking and the educational sector. Through his sound management knowledge and experience, he has driven many highly reputable companies towards success. With a Doctorate in Management Studies, Dr. Mortha possess an immaculate skill set with abilities to quickly learn intricacies, solve complex problems.",
  },
  {
    board_name: " Ms.Shubra Hanjabam",
    board_role: "Director ",
    board_img: Board03,
    board_text:
      " Ms. Shubhra is an Experienced food industry professional and a founder of Meira Foods. She has a strong and demonstrated history of working in the food & beverages industry. Shubhra is a well-known entrepreneur in Manipur and an active social worker. Skilled in Nonprofit Organizations, Fundraising, Capacity Building, Program Development, and Motivational Speaking. She is a strong business development professional with a Master's degree focused in Foods, Nutrition, and Related Services from Banasthali Vidyapith.",
  },
  {
    board_name: "Mr. Nandakumar V",
    board_role: " Director ",
    board_r: " BE, MBA-Finance ",
    board_img: Board04,
    board_text:
      " Nandakumar is a management graduate with a good exposure into banking and finance. He started off his career with ICICI bank and then turned into an entrepreneur and currently he is the Director of ACCENT STEELS, a company that supplies accessories to large mining equipments.  ",
  },
  {
    board_name: "Mr. Pon Aananth",
    board_role: "MD & CEO",
    board_img: Board01,
    board_text:
      "Aananth is an operations and finance executive with twenty years of exclusive experience in financial inclusion. He strongly believes that women are the change makers and access to finance is the key lever to empower women economically. He worked across corporates, national and global non-profits.  A postgraduate in Agriculture Economics and a Certified Microfinance Expert (CMF) and, CERM from Frankfurt School of Finance and Management.",
  },
];

export default board;
