import React from 'react';

const productServices = () => {
  return (
    <div>
       <div className="commen-h">
        <div className="tit">Products and Services</div>
      </div>
    </div>
  );
}

export default productServices;
