import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Pin01 from "../assets/team/what.jpg.jpg";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const What = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>What We Do - Pinke Capital</title>
        <meta
          name="description"
          content="Learn about what we do and our mission to empower women entrepreneurs through access to capital and business support services."
        />
        <meta
          name="keywords"
          content="what we do, mission, empower, women entrepreneurs, access to capital, business support services"
        />
      </Helmet>
      <div className="com-img">
        <div className="common-head  board">
          <h2>What We Do</h2>
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to="/">
                Home
              </Link>
              <Typography color="text.primary">What We Do</Typography>
            </Breadcrumbs>
          </div>
        </div>
      </div>
      <div className="pinke-container container">
        <div className="row">
          <div className="col-lg-4 col-md-4 pt-5 col-sm-6 col-xs-6 col-xl-4 mem-card">
            <img src={Pin01} alt="" className="pinke-staff" />
          </div>
          <div className="col-lg-8 col-md-8 col-sm-6 col-xs-6 col-xl-8 mem-card">
            <h2 className="pink-">What We Do ?</h2>
            <div className="pinke-text">
              Microfinance is an important tool to help solve a much bigger
              issue—but it has limitations currently, and it’s just one part of
              a greater solution. Removing the obstacles and facilitating women
              to have <b>access to adequate capital </b> and resources that they
              need in emerging economies is the key to build a sustainable
              future for women where they become much greater contributors to
              local economies. It not only helps equality for women; it also
              serves to help societies grow in general and also to gain more
              equality.
            </div>
            <div className="pinke-text">
              Our core work is twofold. One is directing adequate and timely
              credit to women-led businesses and the other one is providing them
              with tools and techniques to strengthen their business through
              appropriate business support services.
            </div>
          </div>
        </div>
      </div>
      <div className="pinke-container container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xl-6 mem-card">
            <h2 className="pink-">Access to Adequate Capital</h2>
            <div className="pinke-text">
              We identify and work with women entrepreneurs who need larger
              capital to expand their business. Timely supply of adequate
              financing helps the women entrepreneurs to reduce the dependency
              on informal sources such as money lenders.
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xl-6 mem-card">
            <h2 className="pink-">Business Support Services</h2>
            <div className="pinke-text">
              High impact making adequate capital when coupled with business
              support services shift the women entrepreneur’s revenue curve to
              the next level. Basic financial literacy, tools of digital
              finance/banking and knowledge and techniques of book keeping helps
              women entrepreneurs to visualise a clear road map for their
              business journey.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default What;
