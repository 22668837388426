import React from "react";
import Modal from "react-modal";
import "../styles/popup.css";
const VideoPopup = ({ isOpen, onClose }) => {
  return (
    <div className="pop-up-container">
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Video Popup"
      >
        <div className="icon-pop-box">
          <button className="close-popup" onClick={onClose}>
            <i className="fa-solid fa-circle-xmark" />
          </button>
        </div>

        <iframe
          width="90%"
          height="350"
          src="https://www.youtube.com/embed/sy_b_X3a_ZQ?si=qUYgDG1qDtaukzBY&start=232"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </Modal>
    </div>
  );
};

export default VideoPopup;
