import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import "../styles/pinke.css";
import Pin01 from "../assets/pink/mem 1.jpg";
import Pin02 from "../assets/pink/asi.jpg";
import Pin03 from "../assets/pink/pinke.png";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const Pinke = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Pinke Academy - Pinke Capital</title>
        <meta
          name="description"
          content="Empowering women entrepreneurs through Pinke Women Entrepreneurship Academy. Learn about our mission, training modules, and dedicated faculty members."
        />
        <meta
          name="keywords"
          content="partners, collaborations, alliances, teamwork, joint ventures"
        />
      </Helmet>
      <div className="com-img">
        <div className="common-head board">
          <h2>Pinke Academy</h2>
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to="/">
                Home
              </Link>
              <Typography color="text.primary">Pinke Academy</Typography>
            </Breadcrumbs>
          </div>
        </div>
      </div>
      <div className="pinke-container container">
        <div className="pinke-box">
          <h2 className="pinke-head mb-5">
            Pinke Women Entrepreneurship Academy
          </h2>
          <img src={Pin03} alt="" className="pin-img" />
          <p className="pinke-text">
            Access to adequate finance helps women entrepreneurs to expand the
            business. But access to finance is not the only factor for the
            success of women-led businesses. Imparting business skills and
            access to markets, market information, and market connect are equally
            important to unlock the potential of women entrepreneurs.
          </p>
          <p className="pinke-text">
            Our Academy focuses on developing need-based training modules like
            financial literacy, digital finance, financial goal setting,
            entrepreneurial skills, bookkeeping, business planning, and business
            communication.
          </p>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-xl-4 mem-card">
            <img src={Pin01} alt="" className="pinke-staff" />
          </div>
          <div className="col-lg-8 col-md-8 col-sm-6 col-xs-6 col-xl-8 mem-card">
            <h2 className="pink-">R Latha - M.Sc, MBA, LLB</h2>
            <p className="text-muted desi">
              <b>Principal</b>
            </p>
            <div className="pinke-text">
              Ms. Latha is a seasoned rural and urban development specialist
              with an exclusive focus on women empowerment. She organized 3000
              women micro-entrepreneurs and federated them into a federation of
              women entrepreneurs. She worked with projects led by the World
              Bank, GAP, and Fair Wear Foundation. Latha has a master’s in
              environmental science from Tamil Nadu Agri University and an MBA
              from Alagappa University. Her 25 years of experience in women
              empowerment, nano-enterprise development, and financial inclusion
              with her grassroots expertise helps the Academy set a right path
              to empower women entrepreneurs with required skillsets.
            </div>
          </div>
        </div>

        {/* <div className="row mem-row">
          <div className="col-lg-8 col-md-8 col-sm-6 col-xs-6 col-xl-8 mem-card sm-ore-2">
            <h2 className="pink-"> Mr Asaithambi - B.COM , MA , LLB</h2>
            <p className="text-muted desi">
              <b>Faculty</b>
            </p>
            <div className="pinke-text ">
              Asaithambi is a development professional with a strong exposure
              to Climate Change, Disaster Resilience, Gender Equity,
              Governance, Health, Poverty Reduction, Sustainable Development
              etc., Areas of Specialization include conservation of Coastal Eco
              Systems, Carbon offsets, Development Research, Disaster
              Preparedness, Financial Inclusion, Livelihoods, Stakeholders
              Management and Local Self Governance. Language and Skills cultural
              Fluency in English and Indian languages (Tamil, Telugu, Kannada,
              Hindi) Ability to work in diverse cultural settings and team
              configurations, gained through wide exposure and learning
              abilities. He is an Alumni of Tata Dhan Academy. Asai has also
              done Diploma in Micro Credit Management. <br /> <br />
              At Pinke Women Entrepreneurship Academy, he helps micro
              entrepreneurs to gain knowledge in digital finance and financial
              goal setting.
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-xl-4 mem-card sm-ore-1">
            <img src={Pin02} alt="" className="pinke-staff" />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Pinke;
