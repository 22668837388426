import React from "react";
import { Helmet } from "react-helmet";
import "../styles/footer.css";

import Logo01 from "../assets/logo/logo.png";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Contact information and social media links for Arul Financiers Pvt Ltd."
        />
        <meta
          name="keywords"
          content="footer, contact, address, social media, Arul Financiers Pvt Ltd"
        />
      </Helmet>
      <div className="footer-container">
        <>
          <section className="contact-area" id="contact">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 offset-lg-3">
                  <div className="contact-content text-center">
                    <a href="/">
                      <img src={Logo01} alt="logo" />
                    </a>
                    <h6>An Unit of Arul Financiers Pvt Ltd</h6>

                    <div className="hr" />
                    <h6>
                      9/2,Elane Enclave,Third Floor, Sathy Road, Ramakrishnapuram, Coimbatore, TamilNadu.{" "}
                      <br />
                      Pincode : 641006
                    </h6>
                    <h6>Phone : 0422-3516124</h6>
                    <div className="contact-social">
                      <ul>
                        <li>
                          <a
                            className="hover-target"
                            href="https://m.facebook.com/profile.php?id=100088834448284&locale2=hi_IN&_rdr"
                          >
                            <i className="fab fa-facebook-f" />
                          </a>
                        </li>
                        <li>
                          <a
                            className="hover-target"
                            href="https://in.linkedin.com/company/adith-corporate-services-pvt-ltd"
                          >
                            <i className="fab fa-linkedin-in" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <footer>
            <p>Copyright © {currentYear} All Rights Reserved.</p>
          </footer>
        </>
      </div>
    </div>
  );
};

export default Footer;
