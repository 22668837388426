import React from 'react';

const FinancialPartners = () => {
  return (
    <div>
      FinancialPartners
    </div>
  );
}

export default FinancialPartners;
