import React, { useEffect } from "react";
import Our01 from "../assets/our/our story.jpg";
import Our02 from "../assets/png/analysis.png";
import Our03 from "../assets/png/diagram.png";

import Vm01 from "../assets/our/vm.jpg";
import "../styles/our.css";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const OurVM = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Vision & Mission - Pinke Capital";

    const metaDescription = document.createElement("meta");
    metaDescription.name = "description";
    metaDescription.content =
      "Discover Pinke Capital's vision, mission, and core values. Learn how we strive to empower women entrepreneurs and promote economic responsibility.";
    document.head.appendChild(metaDescription);
    return () => {
      document.head.removeChild(metaDescription);
    };
  }, []);

  return (
    <div>
      <div className="com-img">
        <div className="common-head  board">
          <h2>Our Story</h2>
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to="/">
                Home
              </Link>
              <Link underline="none" color="inherit">
                About
              </Link>
              <Typography color="text.primary">Our Story</Typography>
            </Breadcrumbs>
          </div>
        </div>
      </div>
      <div className="our-company-container">
        <div className="our-vm-img">
          <img src={Vm01} alt="" />
        </div>
        <div className="our--box">
          <div className="our-left our-mission">
            <div className="our-h">Our Vision</div>
            <div className="our-inside">
              <div className="">
                The vision of Pinke Capital is to remain as a favoured financial
                institution to womenentrepreneurs.
              </div>
            </div>
          </div>

          <div className="our-right our-vision">
            <div className="our-h">Our Mission</div>
            <div className="our-inside">
              <div className="">
                PINKE Capital’s mission is to innovate and facilitate access to
                high impact making financial products and business support
                services to women led businesses.
              </div>
            </div>
          </div>
        </div>
        <div className="core-value-container">
          <h2 className="c-title">Our Core Values</h2>
          <div className="core-box">
            <ul className="core-ul">
              <li>Innovation</li>
              <li>Integrity</li>
              <li>Effective Utilization of resources</li>
              <li>Economic responsibility</li>
              <li>Generative Governance</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurVM;
