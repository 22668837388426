import Board01 from "../assets/advisar/Alix Lebec Bio Photo.png";
import Board02 from "../assets/advisar/123.png";

const advisar = [
  {
    board_name: "Ms. Alix Lebec ",
    board_role: "Founder, MD & CEO",
    board_img: Board01,

    board_web: "lebec-consulting ",
    board_link:
      "https://www.linkedin.com/in/alix-lebec-1821a71?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    board_text_1:
      "Born in France and raised in South Korea and China, Alix Lebec is a seasoned entrepreneur, impact investor, and strategic advisor with a global perspective and two decades of experience across philanthropy, impact investing, and sustainable finance. Alix is continuously working at the forefront of financial innovation, gender equality, and social impact. She advises corporations, foundations, high-net-worth individuals, financial institutions, and entrepreneurs scaling solutions that prioritize people and planet alongside long-term profitability. Most recently, she was a chief architect of WaterEquity—a global asset manager solving the global water crisis. In 2020, Alix founded Lebec Consulting to fill a market gap with new, dynamic approaches that leverage capital markets for good, help philanthropists and impact investors maximize their impact, and invest in women-led businesses in developing and emerging markets. Alix worked in the past with World Bank, Clinton Global Initiative and Water.org.",
    board_text_2:
      "Her successful track record has brought Lebec Consulting notable clients like the Global Business Coalition for Education; Elevate Prize Foundation and their community of social entrepreneurs; Acumen; Urban Growers Collective; Root Capital; The Global Fund to Fight AIDS, Tuberculosis and Malaria; and Alliance Global Advisors, among others. Alix is also an advisor to Polymath Ventures—Latin America’s premier Seed Fund and Venture Studio, and is a co-founder of the World Climate Tech Summit.",
    board_text_3:
      "Alix holds a Master of Science in Social Policy and Development with Merit from the London School of Economics and graduated Summa Cum Laude from the American University of Paris Summa with a Bachelor of Arts in International Business. She serves on the board of iDE and is a member of 100 Women in Finance. ",
  },
  {
    board_name: "Ms. Tanwi Kumari ",
    board_role: "Regional Head ",
    board_r: "South and South-east Asia ",
    board_img: Board02,
    board_link:
      "https://www.linkedin.com/in/tanwi-kumari-05b8453?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    board_web: "Microfinanza ",
    board_text_1:
      "Have known Tanwi from her IFMR days with her strong orientation towards purpose driven missions and creating innovative financing instruments. She has proven leadership in research, technical advisory, strategy, building alliances, business development, funding relations and resource mobilization across several countries with both bi-lateral and multilateral institutions. We are glad to have her as strategic advisor to the Pinke Capital.",
  },
];

export default advisar;
