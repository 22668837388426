import React, { useState } from "react";
import "../styles/nav.css";
import Fb01 from "../assets/social icon/facebook.png";
import Twi01 from "../assets/social icon/twitter.png";
import Li01 from "../assets/social icon/linkedin.png";
import MainLogo01 from "../assets/logo/logo.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import MobileNav from "./MobileNav";

export default function Navbar() {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Navigation bar for easy access to different sections of the website."
        />
        <meta
          name="keywords"
          content="navbar, navigation, menu, links, react, mobile nav"
        />
      </Helmet>
      <div className="mobile-navbar">
        <div className="mobile-nav">
          <div className="mobile-logo">
            <Link to="/">
              <img src={MainLogo01} className="logo" alt="" />
            </Link>
          </div>
          <MobileNav />
        </div>
      </div>

      <div className="navbar-container">
        <div className="nav-bottom">
          <div className="main-logo">
            <Link to="/">
              <img src={MainLogo01} className="logo" alt="" />
            </Link>
          </div>
          <div className="av-ul">
            <div
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              className="hover-box"
            >
              <div className="hover-title">About Us</div>
              {isHovered && (
                <div className="hover-ul">
                  <div className="hover-in-box">
                    <li>
                      <Link to="/who-we-are" className="hover-li">
                        Who We Are
                      </Link>
                    </li>
                    <li>
                      <Link to="/board" className="hover-li">
                        Board
                      </Link>
                    </li>
                    <li>
                      <Link to="/strategic-advisors" className="hover-li">
                        Advisors
                      </Link>
                    </li>
                    <li>
                      <Link to="/our-team" className="hover-li">
                        Team
                      </Link>
                    </li>
                     <li>
                      <Link to="/our-vision-mission" className="hover-li">
                        Our Vision , Mission & Values                 </Link>
                    </li> 
                    {/* <li>
                    <Link to="/our-core-value" className="hover-li">
                      Core Values
                    </Link>
                  </li> */}
                  </div>
                </div>
              )}
            </div>

            <div>
              <Link to="/What-we-do" className="nav-li">
                What We Do
              </Link>
            </div>
            <div>
              <Link to="/financial-solutions" className="nav-li">
                Financial Solutions
              </Link>
            </div>

            <div
              className="hover-box"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <div className="hover-title"> Our Impact</div>
              {isHovered && (
                <ul className="hover-ul">
                  <div className="hover-in-box">
                  <li>
                    <Link to="/voice-of-our-clients" className="hover-li">
                      Voice Of Our Clients
                    </Link>
                  </li>
                  <li>
                    <Link to="/out-outreach" className="hover-li">
                      Our Outreach
                    </Link>
                  </li>
                  </div>
                </ul>
              )}
            </div>

            {/* <div
              className="hover-box"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <div className="hover-title">Partners</div>
              {isHovered && (
                <ul className="hover-ul">
                  <div className="hover-in-box">

                  <li>
                    <Link to="/partners/#landing-partners" className="hover-li">
                    Banking Partners
                    </Link>
                  </li>
                  <li>
                    <Link to="/partners/#tech-partners" className="hover-li">
                      Technology Partners
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/partners/#strategic-partners"
                      className="hover-li"
                    >
                      Strategic Partners
                    </Link>
                  </li>
                  </div>
                </ul>
              )}
            </div> */}
            
           

         
           
            <div>
              <Link to="/partners" className="nav-li">
                Partners
              </Link>
            </div>
            <div>
              <Link to="/pinke-academy" className="nav-li">
                Pinke Academy
              </Link>
            </div>
          
            <div>
              <Link to="/resources" className="nav-li">
                Resources
              </Link>
            </div>
            <div>
              <Link to="/contact-us" className="nav-li">
                Contact us
              </Link>
            </div>

            {/* <div
              className="hover-box"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <div className="hover-title">Contact us</div>
              {isHovered && (
                <ul className="hover-ul">
                  <div className="hover-in-box">

                  <li>
                    <Link to="/contact-us" className="hover-li">
                      Contact Details
                    </Link>
                  </li>
                  <li>
                    <Link to="/customer-grievences" className="hover-li">
                      Customer Grievences
                    </Link>
                  </li>
                  </div>
                </ul>
              )}
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
